import { AppPlugin, AppRootProps } from '@grafana/data';
import { onPluginPreload } from './preload';
import React, { ComponentClass, lazy, Suspense } from 'react';
// @ts-ignore
import packageJson from '../package.json';
import { LoadingPlaceholder } from '@grafana/ui';

const LazyApp = lazy(() => import('./App'));

const App = (props: AppRootProps<{}>) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyApp {...props} />
  </Suspense>
);

interface CloudHomeSettings {}

export const plugin = new AppPlugin<CloudHomeSettings>().setRootPage(App as unknown as ComponentClass<AppRootProps>);

onPluginPreload();
