// Logs
export const LOGS_FREE_INCLUDED = 50; // gb
export const LOGS_USAGE = 'grafanacloud_org_logs_usage';

// Metrics
export const METRICS_FREE_INCLUDED = 10000;
export const METRICS_USAGE_FREE = 'grafanacloud_instance_active_series';
export const METRICS_USAGE_PRO = 'grafanacloud_org_metrics_billable_series';

// Traces
export const TRACES_FREE_INCLUDED = 50;
export const TRACES_USAGE = 'grafanacloud_org_traces_usage';

// Users
export const USERS_FREE_INCLUDED = 3;
export const USERS_USAGE = 'grafanacloud_org_grafana_billable_users';

// K6
export const K6_USAGE = 'grafanacloud_org_k6_virtual_user_hours_usage';
export const K6_FREE_INCLUDED = 500;

// Profiles
export const PROFILES_FREE_INCLUDED = 50; // gb
export const PROFILES_USAGE = 'grafanacloud_org_profiles_usage';

// IRM
export const IRM_FREE_INCLUDED = 3;
export const IRM_USAGE = 'grafanacloud_org_irm_users';

// FEO11Y
export const FEO11Y_FREE_INCLUDED = 50000;
export const FEO11Y_USAGE = 'grafanacloud_org_fe_o11y_billable_sessions';

// APPO11Y
export const APPO11Y_FREE_INCLUDED = 2232;
export const APPO11Y_USAGE = 'grafanacloud_org_app_o11y_billable_sessions';

// Synthetic Monitoring
export const SYNTHETIC_MONITORING_FREE_INCLUDED = 100000;
export const SYNTHETIC_MONITORING_USAGE = 'grafanacloud_org_sm_billable_check_executions';

export const LIMIT_TITLE = 'Limit';
