import { isSignedIn } from 'feature/common/utils/UserUtils';
import { lsGet, lsKeys } from 'feature/common/utils/misc';
import { isGrowthRoutingScreen } from 'feature/notification-banners/utils/misc';
import { BannerType } from 'feature/notification-banners/types/Banner';
import { freeUserDataLimits } from 'feature/notification-banners/consts/billing';
import { BillingData } from 'feature/notification-banners/types/Billing';
import { CloudOrgData, OrgBillingDimensionsData } from 'feature/common/types/CloudOrgData';
import { isFree, isTrial } from 'feature/common/utils/orgInfoUtils';
import { getBillingData } from 'feature/notification-banners/api/notificationBannerApi';
import { User } from 'feature/notification-banners/types/User';

export const BannerDisplayOrder = [
  BannerType.Trial,
  BannerType.Limit,
  BannerType.Free,
  BannerType.K6LimitReached,
  BannerType.FeO11YLimitReached,
  BannerType.AppO11YLimitReached,
  BannerType.SyntheticMonitoringLimitReached,
];

const checkMetricsLimits = (data: BillingData): boolean => {
  return (
    data.metrics >= freeUserDataLimits.metrics ||
    data.logs >= freeUserDataLimits.logs ||
    data.users >= freeUserDataLimits.users ||
    data.traces >= freeUserDataLimits.traces
  );
};

const checkK6VUhsLimit = (data: BillingData): boolean => {
  return data.k6Metrics >= freeUserDataLimits.k6Metrics;
};

const checkFeO11YUsageLimit = (data: BillingData): boolean => {
  return data.feO11Y >= freeUserDataLimits.feO11Y;
};

const checkAppO11YUsageLimit = (data: BillingData): boolean => {
  return data.appO11Y >= freeUserDataLimits.appO11Y;
};

const checkSyntheticMonitoringUsageLimit = (data: BillingData): boolean => {
  return data.sm >= freeUserDataLimits.sm;
};

export const checkIfDisplayBanner = (
  type: BannerType,
  grafanaCloud: CloudOrgData,
  billingData: BillingData
): boolean => {
  if (!isSignedIn() || isGrowthRoutingScreen(window.location.href)) {
    return false;
  }
  switch (type) {
    case BannerType.Trial:
      return isTrial(grafanaCloud) && lsGet(lsKeys.showTrialBanner) !== false;

    case BannerType.Limit:
      return isFree(grafanaCloud) && checkMetricsLimits(billingData) && lsGet(lsKeys.showLimitExceededBanner) !== false;

    case BannerType.Free:
      return isFree(grafanaCloud) && lsGet(lsKeys.showFreeBanner) !== false;

    case BannerType.K6LimitReached:
      return isFree(grafanaCloud) && checkK6VUhsLimit(billingData) && lsGet(lsKeys.showK6LimitReached) !== false;

    case BannerType.FeO11YLimitReached:
      return (
        isFree(grafanaCloud) && checkFeO11YUsageLimit(billingData) && lsGet(lsKeys.showFeO11YLimitReached) !== false
      );

    case BannerType.AppO11YLimitReached:
      return (
        isFree(grafanaCloud) && checkAppO11YUsageLimit(billingData) && lsGet(lsKeys.showAppO11YLimitReached) !== false
      );

    case BannerType.SyntheticMonitoringLimitReached:
      return (
        isFree(grafanaCloud) &&
        checkSyntheticMonitoringUsageLimit(billingData) &&
        lsGet(lsKeys.showSyntheticMonitoringLimitReached) !== false
      );

    default:
      return false;
  }
};

export const isNotificationBannerVisible = (
  cloudOrgData: CloudOrgData,
  orgBillingData: OrgBillingDimensionsData,
  users: User[] = []
) => {
  if (!!cloudOrgData && !!users) {
    const billingData = getBillingData(cloudOrgData, orgBillingData, users);

    for (let i = 0; i < BannerDisplayOrder.length; i++) {
      if (checkIfDisplayBanner(BannerDisplayOrder[i], cloudOrgData, billingData)) {
        return true;
      }
    }
  }
  return false;
};
