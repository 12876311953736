export const getGreetingBasedOnTime = () => {
  let time = new Date();

  let hours = time.getHours();

  if (hours >= 5 && hours <= 11) {
    return 'Good morning.';
  } else if (hours >= 12 && hours <= 17) {
    return 'Good afternoon.';
  } else {
    return 'Good evening.';
  }
};

export const getGrafanaDomain = () => {
  const host = window.location.hostname;
  if (host.endsWith('.grafana-dev.net')) {
    return 'https://grafana-dev.com';
  }
  if (host.endsWith('.grafana-ops.net')) {
    return 'https://grafana-ops.com';
  }
  return 'https://grafana.com';
};

export const getActiveUsersLink = (areMoreUsers: boolean, orgSlug = ''): string => {
  if (areMoreUsers) {
    return getMembersLink(orgSlug);
  } else {
    return getSubscriptionLink(orgSlug);
  }
};

export const getMembersLink = (orgSlug = '') => {
  const domain = getGrafanaDomain();
  return !!orgSlug ? `${domain}/orgs/${orgSlug}/members` : `${domain}/profile/org/members`;
};

export const getSubscriptionLink = (orgSlug = '') => {
  const domain = getGrafanaDomain();
  return !!orgSlug ? `${domain}/orgs/${orgSlug}/subscription` : `${domain}/profile/org/subscription`;
};
