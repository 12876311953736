import React, { useEffect } from 'react';
import { BannerType, PageBannerSeverity } from '../types/Banner';
import { BillingData } from '../types/Billing';
import { getLimitContent, updateUrlParams } from '../utils/misc';
import { UpgradeNow } from './UpgradeNow';

export const LimitExceededNotificationBanner = ({
  data,
  orgSlug,
  orgId,
  severity,
}: {
  data: BillingData;
  orgSlug: string;
  orgId: number;
  severity: PageBannerSeverity;
}) => {
  useEffect(() => {
    updateUrlParams('hg_notification_limit');
  }, []);

  const content = getLimitContent(data);
  return (
    <>
      {content}
      <UpgradeNow bannerType={BannerType.Limit} orgSlug={orgSlug} orgId={orgId} severity={severity} />
    </>
  );
};
