import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { PageBannerSeverity } from '../types/Banner';

export const getStyles = (theme: GrafanaTheme2, severity: PageBannerSeverity) => {
  const severityValue = PageBannerSeverity[severity] as keyof typeof PageBannerSeverity;
  const severityTextColor = theme.colors[severityValue].contrastText;
  return {
    link: css`
      border: 1px solid ${severityTextColor};
      border-radius: ${theme.shape.borderRadius(1)};
      color: ${severityTextColor};
      margin-left: ${theme.spacing(1)};
      display: inline-block;
      padding: 1px 6px;

      &:hover {
        background: none;
      }
    `,
  };
};
