import { BusEventWithPayload } from '@grafana/data';

export enum BannerType {
  Free = 'hg_notification_free',
  Limit = 'hg_notification_limit',
  Trial = 'hg_notification_trial',
  Update = 'hg_notification_update',
  TopNav = 'hg_notification_topnav',
  K6Available = 'hg_notification_k6available',
  K6LimitReached = 'hg_notification_k6vuh_limit',
  FeO11YLimitReached = 'hg_notification_feo11y_limit',
  AppO11YLimitReached = 'hg_notification_appo11y_limit',
  SyntheticMonitoringLimitReached = 'hg_notification_sm_limit',
}

export enum BannerEvent {
  Close = 'close',
  Upgrade = 'upgrade',
  Feedback = 'feedback',
  GoK6 = 'gok6',
}

export class PageBannerDisplayEvent extends BusEventWithPayload<PageBannerEventPayload | null> {
  static type = 'page-banner-display';
}

type PageBannerEventPayload = {
  onClose?: () => void;
  severity?: PageBannerSeverity;
  body: React.ElementType<PageBannerBodyProps>;
};

type PageBannerBodyProps = {
  severity: PageBannerSeverity;
};

export enum PageBannerSeverity {
  info,
  warning,
  error,
}
