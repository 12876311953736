import {
  LOGS_FREE_INCLUDED,
  METRICS_FREE_INCLUDED,
  TRACES_FREE_INCLUDED,
  USERS_FREE_INCLUDED,
  K6_FREE_INCLUDED,
  FEO11Y_FREE_INCLUDED,
  APPO11Y_FREE_INCLUDED,
  SYNTHETIC_MONITORING_FREE_INCLUDED,
} from 'feature/usage/consts/usage';

export const freeUserDataLimits = {
  metrics: METRICS_FREE_INCLUDED,
  logs: LOGS_FREE_INCLUDED,
  traces: TRACES_FREE_INCLUDED,
  users: USERS_FREE_INCLUDED,
  k6Metrics: K6_FREE_INCLUDED,
  feO11Y: FEO11Y_FREE_INCLUDED,
  appO11Y: APPO11Y_FREE_INCLUDED,
  sm: SYNTHETIC_MONITORING_FREE_INCLUDED,
};
