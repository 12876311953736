// source: https://github.com/grafana/grafana-com/blob/main/packages/grafana-com-base/lib/types/org-grafana-cloud.ts
export enum Tiers {
  Disabled = 'disabled',
  Free = 'free',
  Trial = 'trial',
  Pro = 'subscribed',
  PendingCancellation = 'pending-cancellation',
  TrialUpgraded = 'trial-upgraded',
  GCPPro = 'gcp-subscribed',
  GCPPending = 'gcp-pending',
  GCPFlatFeePro = 'gcp-flat-fee-subscribed',
  // There should not be any new orgs with this status (GCPFlatFeeOnHold)
  GCPFlatFeeOnHold = 'gcp-flat-fee-on-hold',
  AzurePro = 'azure-subscribed',
  AzureSuspended = 'azure-suspended',
  AzurePending = 'azure-pending',
  AwsPro = 'aws-subscribed',
  AwsPending = 'aws-pending',
}
