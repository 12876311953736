import semver from 'semver';
import { config } from '@grafana/runtime';

export const PLUGIN_URL = `/a/cloud-home-app/`;

export const lsGet = (key: string) => {
  const item = localStorage.getItem(key);

  if (!item) {
    return;
  }

  return JSON.parse(item);
};

export const lsExists = (key: string) => {
  const item = localStorage.getItem(key);

  return Boolean(item);
};

export const lsSave = (key: string, value: object | string) => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }

  if (typeof value !== 'string') {
    console.error("Can't store unrecognized format value");
  }

  localStorage.setItem(key, value);
};

export const lsRemove = (key: string) => {
  localStorage.removeItem(key);
};

export const lsKeys = {
  showFreeBanner: 'showFreeBanner',
  showTrialBanner: 'showTrialBanner',
  showLimitExceededBanner: 'showLimitExceededBanner',
  isNewUserTestVariation: 'newUserTestVariation',
  onboardingVisited: 'onboardingVisited',
  showK6LimitReached: 'showK6LimitReached',
  showFeO11YLimitReached: 'showFeO11YLimitReached',
  showAppO11YLimitReached: 'showAppO11YLimitReached',
  showSyntheticMonitoringLimitReached: 'showSyntheticMonitoringLimitReached',
};

export function isConnectionsSectionPresent() {
  return semver.gte(config.buildInfo.version, '9.5.0');
}

function isGrafanaAtLeast10() {
  // 10.0.0-cloud1 is a prerelease of 10.0.0, and therefore smaller than 10.0.0,
  // but we want to return true for it.
  // The largest minor release in Grafana 9 was 9.5.
  return semver.gt(config.buildInfo.version, '9.9.0');
}

export const getConnectionUrl = (): string => {
  if (isGrafanaAtLeast10()) {
    return '/connections/add-new-connection';
  } else if (isConnectionsSectionPresent()) {
    return '/connections/connect-data';
  } else {
    return '/a/grafana-easystart-app';
  }
};

export function waitForElm(selector: string) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function interpolateString(text: string, params: { [key: string]: string }) {
  if (text && params && Object.keys(params).length > 0) {
    let result = text;
    Object.keys(params).forEach((key) => {
      const pattern = new RegExp(`\{\{${key}\}\}`, 'g');
      result = result.replace(pattern, params[key]);
    });
    return result;
  }
  return text;
}
