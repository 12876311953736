import React, { useEffect } from 'react';
import { BannerType, PageBannerSeverity } from '../types/Banner';
import { updateUrlParams } from '../utils/misc';
import { UpgradeNow } from './UpgradeNow';

export const TrialNotificationBanner = ({
  trialExpiresAt,
  orgSlug,
  orgId,
  severity,
}: {
  trialExpiresAt: string;
  orgSlug: string;
  orgId: number;
  severity: PageBannerSeverity;
}) => {
  useEffect(() => {
    updateUrlParams('hg_notification_trial');
  }, []);

  const expiresAt = new Date(trialExpiresAt).toLocaleDateString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <>
      You have uncapped usage until {expiresAt}. Upgrade plans to continue using Grafana Cloud with unlimited,
      pay-as-you-go usage.
      <UpgradeNow bannerType={BannerType.Trial} orgSlug={orgSlug} orgId={orgId} severity={severity} />
    </>
  );
};
