import React, { useEffect } from 'react';
import { BannerType, PageBannerSeverity } from '../types/Banner';
import { updateUrlParams } from '../utils/misc';
import { UpgradeNow } from './UpgradeNow';

export const FreeNotificationBanner = ({
  orgSlug,
  orgId,
  severity,
}: {
  orgSlug: string;
  orgId: number;
  severity: PageBannerSeverity;
}) => {
  useEffect(() => {
    updateUrlParams('hg_notification_free');
  }, []);

  return (
    <>
      Grafana Cloud Free includes a lot, but has some limitations. Upgrade plans for pay-as-you-go usage, premium
      support and more.
      <UpgradeNow
        bannerType={BannerType.Free}
        orgSlug={orgSlug}
        orgId={orgId}
        severity={severity}
        data-testid={BannerType.Free}
      />
    </>
  );
};
