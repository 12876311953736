import { isSignedIn, isUserAdmin } from './feature/common/utils/UserUtils';
import {
  cleanUpNotificationBanners,
  initPageBanner,
} from './feature/notification-banners/components/InitNotificationBanners';

/*
 * Method to collect all things to execute when the plugin is preloaded.
 * Note:
 * Since this doesn't block execution, the user can freely initiate other
 * actions while this is being executed, and therefore race conditions
 * can occur.
 */
export function onPluginPreload(): void {
  if (isSignedIn() && isUserAdmin()) {
    initPageBanner();
  } else {
    cleanUpNotificationBanners();
  }
}
