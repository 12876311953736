import { useStyles2 } from '@grafana/ui';
import React from 'react';
import { BannerEvent, BannerType, PageBannerSeverity } from '../types/Banner';
import { dispatchRudderStackEvent } from '../utils/misc';
import { getStyles } from './Button.styles';
import { getSubscriptionLink } from 'feature/home/components/refresh-design/helpers';

export const UpgradeNow = ({
  orgSlug,
  orgId,
  severity,
  bannerType,
}: {
  orgSlug: string;
  orgId: number;
  severity: PageBannerSeverity;
  bannerType: BannerType;
}) => {
  const styles = useStyles2((theme) => getStyles(theme, severity));

  const handleUpgradeClick = () => {
    dispatchRudderStackEvent(bannerType, BannerEvent.Upgrade, orgId);
  };

  return (
    <a
      href={getSubscriptionLink(orgSlug)}
      target="_blank"
      rel="noreferrer"
      onClick={handleUpgradeClick}
      className={styles.link}
    >
      Upgrade now
    </a>
  );
};
