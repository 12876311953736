import React from 'react';
import {
  getBillingData,
  getHostedDataDetails,
  getOrgBillingDimensionsData,
  getOrgData,
  getOrgUsers,
} from '../api/notificationBannerApi';
import { dispatchRudderStackEvent, sleep } from '../utils/misc';
import { lsKeys, lsSave } from 'feature/common/utils/misc';
import { getAppEvents } from '@grafana/runtime';
import { BannerEvent, BannerType, PageBannerDisplayEvent, PageBannerSeverity } from '../types/Banner';
import { BillingData } from '../types/Billing';
import { FreeNotificationBanner } from './FreeNotificationBanner';
import { LimitExceededNotificationBanner } from './LimitExceededNotificationBanner';
import { TrialNotificationBanner } from './TrialNotificationBanner';
import { K6LimitReachedNotificationBanner } from './K6LimitReachedNotificationBanner';
import { FeO11YLimitReachedNotificationBanner } from './FeO11YLimitReachedNotificationBanner';
import { AppO11YLimitReachedNotificationBanner } from './AppO11YLimitReachedNotificationBanner';
import { SyntheticMonitoringLimitNotificationBanner } from './SyntheticMonitoringLimitNotificationBanner';
import { CloudOrgData } from 'feature/common/types/CloudOrgData';
import { HostedDataDetails } from 'feature/common/types/HostedDataDetails';
import { EventBus } from '@grafana/data';
import { BannerDisplayOrder, checkIfDisplayBanner } from 'feature/notification-banners/utils/notificationBanners';
import { getTrialEndDate } from 'feature/common/utils/orgInfoUtils';

const generateTrialBannerEvent = (trialExpiresAt: string, orgSlug: string, orgId: number) => {
  const severity = PageBannerSeverity.info;
  return new PageBannerDisplayEvent({
    onClose: function () {
      lsSave(lsKeys.showTrialBanner, 'false');
      window.dispatchEvent(new Event('storage'));
      dispatchRudderStackEvent(BannerType.Trial, BannerEvent.Close, orgId);
    },
    severity,
    body: function Component() {
      return (
        <TrialNotificationBanner trialExpiresAt={trialExpiresAt} orgSlug={orgSlug} orgId={orgId} severity={severity} />
      );
    },
  });
};

const generateFreeBannerEvent = (orgSlug: string, data: BillingData, orgId: number) => {
  const severity = PageBannerSeverity.warning;
  return new PageBannerDisplayEvent({
    onClose: function () {
      lsSave(lsKeys.showFreeBanner, 'false');
      window.dispatchEvent(new Event('storage'));
      dispatchRudderStackEvent(BannerType.Free, BannerEvent.Close, orgId);
    },
    severity,
    body: function Component() {
      return <FreeNotificationBanner orgSlug={orgSlug} orgId={orgId} severity={severity} />;
    },
  });
};

const generateLimitExceededBannerEvent = (orgSlug: string, data: BillingData, orgId: number) => {
  const severity = PageBannerSeverity.error;
  return new PageBannerDisplayEvent({
    severity,
    onClose: function () {
      lsSave(lsKeys.showLimitExceededBanner, 'false');
      window.dispatchEvent(new Event('storage'));
      dispatchRudderStackEvent(BannerType.Limit, BannerEvent.Close, orgId);
    },
    body: function Component() {
      return <LimitExceededNotificationBanner data={data} orgSlug={orgSlug} orgId={orgId} severity={severity} />;
    },
  });
};

const generateK6VUhsLimitReachedBannerEvent = (orgId: number, data: BillingData, orgSlug: string) => {
  const severity = PageBannerSeverity.error;

  return new PageBannerDisplayEvent({
    onClose: function () {
      lsSave(lsKeys.showK6LimitReached, 'false');
      window.dispatchEvent(new Event('storage'));
      dispatchRudderStackEvent(BannerType.K6LimitReached, BannerEvent.Close, orgId);
    },
    severity,
    body: function Component() {
      return <K6LimitReachedNotificationBanner orgId={orgId} orgSlug={orgSlug} severity={severity} />;
    },
  });
};

const generateFeO11YLimitReachedBannerEvent = (orgId: number, data: BillingData, orgSlug: string) => {
  const severity = PageBannerSeverity.error;

  return new PageBannerDisplayEvent({
    onClose: function () {
      lsSave(lsKeys.showFeO11YLimitReached, 'false');
      window.dispatchEvent(new Event('storage'));
      dispatchRudderStackEvent(BannerType.FeO11YLimitReached, BannerEvent.Close, orgId);
    },
    severity,
    body: function Component() {
      return <FeO11YLimitReachedNotificationBanner orgId={orgId} orgSlug={orgSlug} severity={severity} />;
    },
  });
};

const generateAppO11YLimitReachedBannerEvent = (orgId: number, data: BillingData, orgSlug: string) => {
  const severity = PageBannerSeverity.error;

  return new PageBannerDisplayEvent({
    onClose: function () {
      lsSave(lsKeys.showAppO11YLimitReached, 'false');
      window.dispatchEvent(new Event('storage'));
      dispatchRudderStackEvent(BannerType.AppO11YLimitReached, BannerEvent.Close, orgId);
    },
    severity,
    body: function Component() {
      return <AppO11YLimitReachedNotificationBanner orgId={orgId} orgSlug={orgSlug} severity={severity} />;
    },
  });
};

const generateSyntheticMonitoringLimitBannerEvent = (orgId: number, data: BillingData, orgSlug: string) => {
  const severity = PageBannerSeverity.error;

  return new PageBannerDisplayEvent({
    onClose: function () {
      lsSave(lsKeys.showSyntheticMonitoringLimitReached, 'false');
      window.dispatchEvent(new Event('storage'));
      dispatchRudderStackEvent(BannerType.SyntheticMonitoringLimitReached, BannerEvent.Close, orgId);
    },
    severity,
    body: function Component() {
      return <SyntheticMonitoringLimitNotificationBanner orgId={orgId} orgSlug={orgSlug} severity={severity} />;
    },
  });
};

const generateNotificationBanner = (
  type: BannerType,
  cloudOrgData: CloudOrgData,
  { orgSlug, orgId }: HostedDataDetails,
  billingData: BillingData
) => {
  switch (type) {
    case BannerType.Trial:
      return generateTrialBannerEvent(getTrialEndDate(cloudOrgData), orgSlug, orgId);

    case BannerType.Limit:
      return generateLimitExceededBannerEvent(orgSlug, billingData, orgId);

    case BannerType.Free:
      return generateFreeBannerEvent(orgSlug, billingData, orgId);

    case BannerType.K6LimitReached:
      return generateK6VUhsLimitReachedBannerEvent(orgId, billingData, orgSlug);

    case BannerType.FeO11YLimitReached:
      return generateFeO11YLimitReachedBannerEvent(orgId, billingData, orgSlug);
    case BannerType.AppO11YLimitReached:
      return generateAppO11YLimitReachedBannerEvent(orgId, billingData, orgSlug);

    case BannerType.SyntheticMonitoringLimitReached:
      return generateSyntheticMonitoringLimitBannerEvent(orgId, billingData, orgSlug);

    default:
      return null;
  }
};

export const publishBannerEvent = (
  type: BannerType,
  cloudOrgData: CloudOrgData,
  hostedDataDetails: HostedDataDetails,
  billingData: BillingData,
  appEvents: EventBus
): boolean => {
  if (checkIfDisplayBanner(type, cloudOrgData, billingData)) {
    const bannerEvent = generateNotificationBanner(type, cloudOrgData, hostedDataDetails, billingData);
    if (bannerEvent) {
      appEvents.publish(bannerEvent);
      return true;
    }
  }
  return false;
};

export const initPageBanner = async () => {
  try {
    await sleep(1000);

    const hostedDataDetails = await getHostedDataDetails();
    const cloudOrgData = await getOrgData(hostedDataDetails.orgSlug);
    const orgBillingDimensionData = await getOrgBillingDimensionsData(hostedDataDetails.orgSlug);
    const users = await getOrgUsers();
    const billingData = getBillingData(cloudOrgData, orgBillingDimensionData, users);
    const appEvents = getAppEvents();

    for (let i = 0; i < BannerDisplayOrder.length; i++) {
      if (publishBannerEvent(BannerDisplayOrder[i], cloudOrgData, hostedDataDetails, billingData, appEvents)) {
        break;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export function cleanUpNotificationBanners(): void {
  localStorage.removeItem(lsKeys.showTrialBanner);
  localStorage.removeItem(lsKeys.showLimitExceededBanner);
}
