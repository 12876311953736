export enum MetricUnit {
  USERS = 'users',
  METRICS = 'metrics',
  LOGS = 'logs',
  TRACES = 'traces',
  K6_TESTS = 'k6-tests',
  PROFILES = 'profiles',
  IRM = 'irm',
  FEO11Y = 'feO11Y',
  APPO11Y = 'appO11Y',
  SYNTHETIC_MONITORING = 'sm',
}
