import React, { useEffect } from 'react';
import { BannerType, PageBannerSeverity } from '../types/Banner';
import { updateUrlParams } from '../utils/misc';

import { UpgradeNow } from './UpgradeNow';

export const FeO11YLimitReachedNotificationBanner = ({
  orgSlug,
  orgId,
  severity,
}: {
  orgId: number;
  orgSlug: string;
  severity: PageBannerSeverity;
}) => {
  useEffect(() => {
    updateUrlParams(BannerType.FeO11YLimitReached);
  }, []);

  return (
    <>
      You have reached your free tier limit for Frontend Observability. Upgrade to Grafana Cloud Pro to track more
      sessions.
      <UpgradeNow
        bannerType={BannerType.FeO11YLimitReached}
        orgSlug={orgSlug}
        orgId={orgId}
        severity={severity}
        data-testid={BannerType.FeO11YLimitReached}
      />
    </>
  );
};
