import { BackendSrvRequest, config, getBackendSrv } from '@grafana/runtime';
import { lastValueFrom } from 'rxjs';
import { QueryError } from 'feature/common/types/QueryError';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { DataSourceInstanceSettings } from '@grafana/data';

export const PLUGIN_ID = 'cloud-home-app';

function fetchApi<D>(
  url: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<SuccessfulFetchResponse<D>> {
  const options: BackendSrvRequest = {
    headers: {},
    url: url,
    showErrorAlert: false,
    ...optionsOverride,
  };
  return lastValueFrom(getBackendSrv().fetch<D>(options));
}

export async function apiGet<D>(url: string, optionsOverride: Partial<BackendSrvRequest> = {}): Promise<D> {
  const response = await fetchApi<D>(url, { ...optionsOverride, method: 'GET' });
  if (isErrorResponse(response)) {
    throw response as ErrorFetchResponse<D>;
  }
  return response?.data;
}

export async function apiPost<D>(url: string, data: any, optionsOverride: Partial<BackendSrvRequest> = {}): Promise<D> {
  const response = await fetchApi<D>(url, { ...optionsOverride, method: 'POST', data });

  if (isErrorResponse(response)) {
    throw response as ErrorFetchResponse<D>;
  }
  return response?.data;
}

interface SuccessfulFetchResponse<T> {
  status: number;
  data: T;
}

interface ErrorFetchResponse<E> {
  status: number;
  data: E;
}

function isErrorResponse<D>(thing: any): boolean {
  return (
    typeof thing === 'object' &&
    'status' in thing &&
    !isNaN(thing.status) &&
    (thing.status < 200 || thing.status >= 300)
  );
}

function getError(
  e: any,
  defaultMessage = 'Error while running the service'
): {
  error: QueryError;
} {
  let code;
  let message = e?.message;
  if (e?.data) {
    message = e.data?.message;
    if (e.data?.error) {
      message = e.data.error.message ?? e.data.error;
      code = e.data.error.code;
    }
  }
  if (!message) {
    message = defaultMessage;
  }
  const response = { error: { data: { message } } as QueryError };
  if (e.status) {
    response.error.status = e.status;
  }
  if (code) {
    response.error.code = code;
  }
  return response;
}

const getBaseQueryOptions = (
  args: string | Partial<BackendSrvRequest>
): { url: string; options: Partial<BackendSrvRequest> } => {
  let options: Partial<BackendSrvRequest>;
  let url;
  if (typeof args === 'string') {
    url = args;
    options = { method: 'GET', headers: {} };
  } else {
    url = (args as BackendSrvRequest).url ?? '';
    options = { ...args, headers: args.headers ?? {} };
    delete options.url;
  }
  return { url, options };
};

type BackendSrvQueryArg = { baseUrl?: string; dataSourceName?: string };

export const backendSrvBaseQuery =
  ({
    baseUrl = '',
    dataSourceName,
  }: BackendSrvQueryArg): BaseQueryFn<string | Partial<BackendSrvRequest>, unknown, QueryError> =>
  async (args) => {
    const { url, options } = getBaseQueryOptions(args);
    let queryUrl = baseUrl + url;

    try {
      // used when querying daa sources
      if (!!dataSourceName) {
        const datasource: DataSourceInstanceSettings | undefined = dataSourceName
          ? Object.values(config.datasources).find((el) => el.name === dataSourceName)
          : undefined;
        if (!datasource) {
          return { error: { data: { message: `No datasource called ${dataSourceName} found.` } } as QueryError };
        }
        queryUrl = `${datasource.url}${url}`;

        if (datasource.basicAuth || datasource.withCredentials) {
          options.withCredentials = true;
        }

        if (datasource.basicAuth && options.headers) {
          options.headers.Authorization = datasource.basicAuth;
        }
      }
      const response = await fetchApi(queryUrl, options);
      if (isErrorResponse(response)) {
        return getError(response);
      }
      return { data: response?.data };
    } catch (error) {
      return getError(error);
    }
  };
