// more info here https://github.com/grafana/grafana-com/blob/main/docs/subscriptions.md
import { CONTRACT_TYPE_NAMES } from '../consts/planNames';
import { Tiers } from '../types/Tiers';
import { CloudOrgData } from 'feature/common/types/CloudOrgData';
import { SubscriptionCodeType } from 'feature/common/types/Subscriptions';
import { MarketplaceSubscriptionDetails } from 'feature/common/types/MarketplaceSubscriptionDetails';

// Notifications
const ONE_DAY = 1000 * 60 * 60 * 24;

const getOrgPlanFromMarketplace = (
  result: CloudOrgData | undefined,
  marketplaceSubscription?: MarketplaceSubscriptionDetails
): Tiers | undefined => {
  let tiers;
  if (!!result && !!marketplaceSubscription?.activeSubscription) {
    const subscribed = isSubscribed(result);
    const free = isFree(result);

    switch (marketplaceSubscription.details.provider) {
      case 'gcp':
        if (subscribed) {
          tiers = Tiers.GCPPro;
        } else if (free) {
          tiers = Tiers.GCPPending;
        }
        break;

      case 'azure':
        if (subscribed) {
          tiers = Tiers.AzurePro;
        } else if (free) {
          tiers = Tiers.AzurePending;
        }
        break;

      case 'aws':
        if (subscribed) {
          tiers = Tiers.AwsPro;
        } else if (free) {
          tiers = Tiers.AwsPending;
        }
        break;
    }
  }
  return tiers;
};

export const getOrgPlan = (result?: CloudOrgData, marketplaceSubscription?: MarketplaceSubscriptionDetails): Tiers => {
  if (!result?.subscriptions || Object.keys(result.subscriptions).length === 0) {
    return Tiers.Disabled;
  }

  let tiers = getOrgPlanFromMarketplace(result, marketplaceSubscription);
  if (!tiers) {
    if (isSubscribed(result) || isPendingDowngrade(result)) {
      return Tiers.Pro;
    }
    if (isFree(result)) {
      return Tiers.Free;
    }
    if (isTrial(result)) {
      return Tiers.Trial;
    }
    if (isPendingCancellation(result)) {
      return Tiers.PendingCancellation;
    }
    if (isTrialUpgraded(result)) {
      return Tiers.TrialUpgraded;
    }
    return Tiers.Free;
  }
  return tiers;
};

export const getContract = (result: CloudOrgData | undefined): CONTRACT_TYPE_NAMES => {
  return result?.contractType;
};

export const getTrialEndDate = (result: CloudOrgData | undefined): string => {
  return result?.subscriptions.current.endDate ?? '';
};

export const getTrialLength = (result: CloudOrgData | undefined): number | undefined => {
  if (!!result?.subscriptions?.current.endDate) {
    const now = new Date();
    const differenceMs = Math.abs(now.getTime() - new Date(result.subscriptions.current.endDate).getTime());
    return Math.round(differenceMs / ONE_DAY);
  }
  return undefined;
};

export const isTrial = (orgData: CloudOrgData | undefined): boolean => {
  if (!!orgData) {
    const current: SubscriptionCodeType = orgData.subscriptions.current.product;
    const trial: boolean = orgData.subscriptions.current.isTrial;
    const next: SubscriptionCodeType | undefined = orgData.subscriptions.next?.product;

    return (
      (current === SubscriptionCodeType.FREE || current === SubscriptionCodeType.PRO) &&
      trial &&
      next === SubscriptionCodeType.FREE
    );
  }
  return false;
};

export const isFree = (orgData: CloudOrgData | undefined) => {
  if (!!orgData) {
    return (
      !orgData.subscriptions.current.isTrial && orgData.subscriptions.current.product === SubscriptionCodeType.FREE
    );
  }
  return true;
};

const isSubscribed = (orgData: CloudOrgData | undefined) => {
  if (!!orgData) {
    const current: SubscriptionCodeType = orgData.subscriptions.current.product;
    const trial: boolean = orgData.subscriptions.current.isTrial;
    const endDate = orgData.subscriptions.current.endDate;

    // also for Sales Led Trials we should return subscribed
    return (
      (current === SubscriptionCodeType.PRO && !trial && !endDate) ||
      (current === SubscriptionCodeType.SS_ADVANCED && !endDate) ||
      current === SubscriptionCodeType.ADVANCED
    );
  }
  return false;
};

const isPendingCancellation = (orgData: CloudOrgData | undefined) => {
  if (!!orgData) {
    const current: SubscriptionCodeType = orgData.subscriptions.current.product;
    const endDate = orgData.subscriptions.current.endDate;
    const next: SubscriptionCodeType | undefined = orgData.subscriptions.next?.product;

    return (
      (current === SubscriptionCodeType.PRO || current === SubscriptionCodeType.SS_ADVANCED) &&
      !!endDate &&
      next === SubscriptionCodeType.FREE
    );
  }
  return false;
};

const isTrialUpgraded = (orgData: CloudOrgData | undefined) => {
  if (!!orgData) {
    const current: SubscriptionCodeType = orgData.subscriptions.current.product;
    const trial: boolean = orgData.subscriptions.current.isTrial;
    const next: SubscriptionCodeType | undefined = orgData.subscriptions.next?.product;

    return current === SubscriptionCodeType.FREE && trial && next === SubscriptionCodeType.PRO;
  }
  return false;
};

const isPendingDowngrade = (orgData: CloudOrgData | undefined) => {
  if (!!orgData) {
    const current: SubscriptionCodeType = orgData.subscriptions.current.product;
    const endDate = orgData.subscriptions.current.endDate;
    const next: SubscriptionCodeType | undefined = orgData.subscriptions.next?.product;

    return current === SubscriptionCodeType.SS_ADVANCED && !!endDate && next === SubscriptionCodeType.PRO;
  }
  return false;
};
