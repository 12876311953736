import React, { useEffect } from 'react';
import { BannerType, PageBannerSeverity } from '../types/Banner';
import { updateUrlParams } from '../utils/misc';

import { UpgradeNow } from './UpgradeNow';

export const K6LimitReachedNotificationBanner = ({
  orgSlug,
  orgId,
  severity,
}: {
  orgId: number;
  orgSlug: string;
  severity: PageBannerSeverity;
}) => {
  useEffect(() => {
    updateUrlParams(BannerType.K6LimitReached);
  }, []);

  return (
    <>
      You have reached your free tier limit for k6 testing. Upgrade to Grafana Cloud Pro to run more tests.
      <UpgradeNow bannerType={BannerType.K6LimitReached} orgSlug={orgSlug} orgId={orgId} severity={severity} />
    </>
  );
};
