import { freeUserDataLimits } from '../consts/billing';
import { BannerEvent, BannerType } from '../types/Banner';
import { BillingData } from '../types/Billing';

// TODO: add unit test
const constructArrayToStrings = (array: []) => {
  return array
    .map((item, index) => {
      if (array.length > 1) {
        if (array.length - 1 === index) {
          return `and ${item}`;
        } else if (array.length - 2 === index) {
          return item;
        }
        return `${item}, `;
      } else {
        return item;
      }
    })
    .join(' ');
};

type LimitContent = {
  firstLine: [];
  secondLine: [];
};

// TODO: add unit test
const constructLimitContent = (
  metrics: boolean,
  logs: boolean,
  users: boolean,
  traces: boolean,
  feO11Y: boolean,
  appO11Y: boolean,
  sm: boolean
) => {
  return {
    firstLine: [
      metrics && 'metrics',
      logs && 'logs',
      traces && 'traces',
      users && 'users',
      feO11Y && 'Frontend Observability',
      appO11Y && 'AppO11y',
      sm && 'Synthetics',
    ].filter((data) => data),
    secondLine: [
      metrics && 'send more metrics',
      feO11Y && 'view more sessions',
      appO11Y && 'use more host hours',
      sm && 'run more test executions',
      (logs || traces) && `${metrics || feO11Y || appO11Y ? 'data' : 'send more data'}`,
      users && 'bring your whole team',
    ].filter((data) => data),
  } as LimitContent;
};

// TODO: add unit test
export const getLimitContent = (data: BillingData) => {
  const { metrics, logs, users, traces, feO11Y, appO11Y, sm } = freeUserDataLimits;

  const isMetricsReached = data.metrics >= metrics;
  const isLogsReached = data.logs >= logs;
  const isUsersReached = data.users >= users;
  const isTracesReached = data.traces >= traces;
  const isFeO11yReached = data.feO11Y >= feO11Y;
  const isAppO11YReached = data.appO11Y >= appO11Y;
  const isSyntheticMonitoringReached = data.sm >= sm;

  const isOnlyUsersReached = !isMetricsReached && !isLogsReached && !isTracesReached && isUsersReached;

  const limitContentObject: LimitContent = constructLimitContent(
    isMetricsReached,
    isLogsReached,
    isUsersReached,
    isTracesReached,
    isFeO11yReached,
    isAppO11YReached,
    isSyntheticMonitoringReached
  );

  const firstLine = constructArrayToStrings(limitContentObject.firstLine);
  const secondLine = constructArrayToStrings(limitContentObject.secondLine);

  if (
    isMetricsReached ||
    isLogsReached ||
    isUsersReached ||
    isTracesReached ||
    isFeO11yReached ||
    isAppO11YReached ||
    isSyntheticMonitoringReached
  ) {
    if (isOnlyUsersReached) {
      return `You’ve reached your free tier limit of ${firstLine}. Upgrade to Grafana Cloud Pro to ${secondLine}.`;
    } else {
      return `You’ve reached your free tier limit for ${firstLine}. Upgrade to Grafana Cloud Pro to ${secondLine}.`;
    }
  }
  return;
};

export const updateUrlParams = (params: string) => {
  const url = new URL(location.href);
  url.searchParams.set('src', params);
  history.pushState(null, '', url.toString());
};

export const dispatchRudderStackEvent = (bannerType: BannerType, action: BannerEvent, orgId: number) => {
  (window as any).rudderanalytics?.track('notification_banner_event', {
    banner_type: bannerType,
    action,
    org_id: orgId,
  });
};

export const isGrowthRoutingScreen = (url: string) => {
  return (
    url.includes('/a/cloud-home-app/onboarding-flow') ||
    url.includes('/a/cloud-home-app/getting-started') ||
    url.includes('/a/cloud-home-app/setup-wizard')
  );
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
