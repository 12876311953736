import { QueryError } from 'feature/common/types/QueryError';

export type CurrentUser = {
  authLabels: string[];
  avatarUrl: string;
  createdAt: string;
  email: string;
  id: number;
  isDisabled: boolean;
  isExternal: boolean;
  isGrafanaAdmin: boolean;
  login: string;
  name: string;
  orgId: number;
  theme: string;
  updatedAt: string;
};

export type User = {
  orgId: number;
  userId: number;
  avatarUrl: string;
  email: string;
  role: string;
  lastSeenAt: string;
  lastSeenAtAge: string;
  login: string;
  name: string;
};

export enum ROLE {
  ANY = 'Any',
  ADMIN = 'Admin',
  VIEWER = 'Viewer',
  EDITOR = 'Editor',
}

export type Org = {
  orgId: number;
  name: string;
  role: ROLE;
};

export type CurrentUserData = {
  currentUser: CurrentUser | undefined;
  isLoading: boolean;
  isAdmin: boolean | undefined;
  isError: boolean;
  isSuccess: boolean;
  error: QueryError | undefined;
  orgRole: ROLE | undefined;
};
