import { getConnectionUrl } from 'feature/common/utils/misc';
import { OnboardingPaths } from 'feature/onboarding/consts/consts';

const basePath = '/a/cloud-home-app';
const onboarding = `${basePath}/onboarding-flow`;
const quickStartsBasePath = `${basePath}/onboarding-flow/quickstart`;

export const appPaths = {
  home: basePath,
  quickStarts: quickStartsBasePath,
  gettingStarted: `${basePath}/getting-started`,
  onboarding,

  setupWizard: `${basePath}/setup-wizard`,

  cloudServices: `${onboarding}/${OnboardingPaths.cloudServices}`,
  createDashboard: `${onboarding}/${OnboardingPaths.createDashboard}`,
  docsPlugin: '/a/grafana-selfservetutorials-app',
  hostedData: `${onboarding}/${OnboardingPaths.hostedData}`,
  linuxNode: `/a/grafana-selfservetutorials-app/linux-node`,
  logsOnboarding: `${onboarding}/${OnboardingPaths.logsOnboarding}`,
  multipleLinuxHosts: `/a/grafana-selfservetutorials-app/monitor-multiple-linux-hosts`,
  prometheus: `${onboarding}/${OnboardingPaths.prometheus}`,
  selfHostedMetrics: `/a/grafana-selfservetutorials-app/self-hosted-metrics`,
  setupGuide: `${basePath}/setup-guide`,
  prometheusQuickstarts: `${quickStartsBasePath}/prometheus`,
};

export const externalPaths = {
  integrationsAndConnections: getConnectionUrl(),
  alertRulesList: '/alerting/list',
  alerts: '/alerting',
  applicationObservability: '/a/grafana-app-observability-app/services',
  applicationO11y: '/a/grafana-app-observability-app/initialize',
  billingDashboard: '/d/i933Yr2nk/billing-usage?orgId=1',
  connectionDatasources: '/connections/datasources',
  dashboards: '/dashboards',
  demoDashboards: '/a/grafana-demodashboards-app',
  faro: 'a/grafana-kowalski-app/apps',
  feO11Y: 'a/grafana-kowalski-app/apps',
  firingAlertRules: '/alerting/list?search=state:firing',
  grafanaPlay: 'https://play.grafana.org/d/',
  kubernetesCluster: '/a/grafana-k8s-app/configuration/cluster-config',
  k6App: `/a/k6-app/initialize`,
  k6Projects: `/a/k6-app/projects`,
  incidents: '/a/grafana-incident-app',
  irm: '/alerts-and-incidents',
  irmActiveUsers: '/alerting/notifications/receivers/new?alertmanager=grafana&query=',
  monitorKubernetes: '/a/grafana-k8s-app/home',
  newAlertRules: '/alerting/new/alerting?returnTo=/alerting/list',
  onCall: '/a/grafana-oncall-app',
  profiles: '/a/grafana-pyroscope-app',
  pyroscope: 'a/grafana-pyroscope-app/tag-explorer',
  syntheticMonitoringApp: '/a/grafana-synthetic-monitoring-app',
  syntheticMonitoringChecks: '/a/grafana-synthetic-monitoring-app/checks/new',
};
