import React, { useEffect } from 'react';
import { BannerType, PageBannerSeverity } from '../types/Banner';
import { updateUrlParams } from '../utils/misc';

import { UpgradeNow } from './UpgradeNow';

export const AppO11YLimitReachedNotificationBanner = ({
  orgSlug,
  orgId,
  severity,
}: {
  orgId: number;
  orgSlug: string;
  severity: PageBannerSeverity;
}) => {
  useEffect(() => {
    updateUrlParams(BannerType.AppO11YLimitReached);
  }, []);

  return (
    <>
      You have reached your free tier limit for AppO11y. Upgrade to Grafana Cloud Pro to use more host hours.
      <UpgradeNow
        bannerType={BannerType.AppO11YLimitReached}
        orgSlug={orgSlug}
        orgId={orgId}
        severity={severity}
        data-testid={BannerType.AppO11YLimitReached}
      />
    </>
  );
};
